<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.worker.count || '~'} Pekerja`"
          :desc="`Total ${$idCurrency($store.state.worker.total)}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <atoms-text-field label="Email Terlampir" v-model.trim="exportData.email" :noLabel="true" />
        <atoms-button class="teal white--text mt-3" style="width: 100%" @click="exportXlsx">
          <v-icon left>mdi-google-spreadsheet</v-icon>
          Ekspor XLSX
        </atoms-button>
        <div class="d-flex align-center" v-if="$isRoles(['Super Admin'])">
          <atoms-button class="primary white--text mt-3" style="width: 100%" @click="resetAllBalances">
            <v-icon left>mdi-refresh</v-icon>
            Reset Semua Saldo Pekerja
          </atoms-button>
        </div>
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <atoms-text class="pa-2"><v-icon left>mdi-magnify</v-icon><span>Pencarian</span></atoms-text>
      <v-row justify="space-between">
        <v-col cols="12" lg="8">
          <atoms-text-field
            label="Cari Pekerja berdasarkan nama / lokasi"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" lg="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary"
            ><v-icon left>mdi-magnify</v-icon>Cari Pekerja</atoms-button
          >
        </v-col>
        <v-col cols="6" lg="2">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getWorkers()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal : {{ sort }}
          </atoms-button>
        </v-col>

        <v-col cols="12" lg="6">
          <v-form
            v-if="!$store.state.client.data || !client.enabled"
            @submit.prevent="
              () => {
                getClients()
                client.term = ''
              }
            "
          >
            <atoms-text-field
              label="Cari Client"
              :no-label="true"
              v-model="client.term"
              appendIcon="mdi-magnify"
              :appendAction="
                () => {
                  getClients()
                  client.term = ''
                }
              "
            />
          </v-form>
          <atoms-text-field v-else label="Cari Client" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      client = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      loadData()
                    }
                  "
                >
                  <v-icon> mdi-delete </v-icon>
                </atoms-button>
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.value"
                  :items="$store.state.client.data.map((x) => x.name)"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="12" lg="6" align="end">
          <atoms-button
            class="secondary lighten-5 secondary--text ml-auto"
            style="width: 100%"
            @click="showEditBulk = true"
            ><v-icon left>mdi-plus</v-icon>Edit Saldo Pekerja
          </atoms-button>
        </v-col>
        <!-- <v-col cols="12" lg="6" align="center">
          <atoms-button
            @click="
              () => {
                if (!$store.state.client.data || !client.enabled) {
                  getClients()
                  client.term = ''
                } else {
                  client = {
                    enabled: false,
                    value: '',
                    item: null,
                    term: '',
                  }
                  loadData()
                }
              }
            "
            :loading="$store.state.loading"
            :style="{ width: '100%' }"
            :class="[
              !$store.state.client.data || !client.enabled
                ? 'primary'
                : 'red white--text',
              'mb-4',
            ]"
            >{{
              !$store.state.client.data || !client.enabled
                ? "Cari Client"
                : "Reset Filter"
            }}</atoms-button
          >
        </v-col> -->

        <!-- <v-col cols="12" md="auto">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /> 
        </v-col> -->
        <v-col cols="12" lg="6">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          />
        </v-col>
        <v-col cols="12" lg="6" class="d-flex justify-center justify-md-end align-center py-0 my-0">
          <v-checkbox v-model="enableDate" hide-spin-buttons />
          <atoms-text class="mr-3">Filter dengan tanggal?</atoms-text>
          <v-checkbox v-model="showCheckboxForSelectingDataWorker" hide-spin-buttons /><atoms-text>
            Pilih Data?
          </atoms-text>
        </v-col>
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableDate" class="my-0">
            <v-row dense>
              <v-col cols="12" md="6">
                <atoms-date-picker
                  label="Dari Tanggal"
                  :modal="from.modal"
                  :value="from.value"
                  @change="
                    (modal, value) => {
                      from.modal = modal
                      from.value = value
                    }
                  "
                />
              </v-col>
              <v-col cols="12" md="6">
                <atoms-date-picker
                  label="Sampai Tanggal"
                  :modal="to.modal"
                  :value="to.value"
                  @change="
                    (modal, value) => {
                      to.modal = modal
                      to.value = value
                    }
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.worker.data && $store.state.worker.data.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div
            v-for="({ ...rest }, i) in $store.state.worker.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col v-if="showCheckboxForSelectingDataWorker" class="shrink">
                <v-checkbox hide-details dense></v-checkbox>
              </v-col>
              <v-col class="shrink">
                <v-avatar color="grey lighten-4 rounded-lg">
                  <atoms-image :enableZoom="true" v-if="rest.photo" :src="rest.photo" />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize((rest.fullName && rest.fullName) || 'Nama tidak dicantumkan')
                }}</atoms-text>
                <atoms-text class="primary--text">{{ (rest && rest.email) || 'pekerja.email@website.com' }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold">Saldo terakhir update : </atoms-text>
                <atoms-text class="primary--text">
                  {{ $moment(rest && rest._updatedDate).format('dddd, DD MMMM YYYY') }}
                </atoms-text>
              </v-col>
              <v-col align="center" v-if="rest.captain">
                <v-chip small class="rounded-lg" color="primary">Kapten</v-chip>
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="showEdit = rest" class="rounded-lg px-4">
                      <v-icon left>mdi-cash</v-icon>
                      <v-list-item-title class="px-2">Edit Saldo</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showHistory = rest" class="rounded-lg px-4">
                      <v-icon left>mdi-clock-outline</v-icon>
                      <v-list-item-title class="px-2">Riwayat Saldo</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                      link
                      @click="createRequest(rest)"
                      class="rounded-lg px-4"
                    >
                      <v-icon left>mdi-clock-outline</v-icon>
                      <v-list-item-title class="px-2"
                        >Request Payment (test)</v-list-item-title
                      >
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <atoms-text>
                  <v-icon left small>mdi-cash-multiple</v-icon>
                  {{ $idCurrency(rest.balance) || '0' }}
                </atoms-text>
                <atoms-text v-if="rest.clientIds && rest.clientIds.length > 0">
                  <v-icon left small>mdi-domain</v-icon>
                  [<a
                    v-for="(x, i) in rest.clients"
                    :key="i"
                    class="text-capitalize"
                    :href="`/attendance/riwayat?search=${rest._id}&client=${x._id}`"
                  >
                    {{ x.name }}
                    {{ i + 1 !== rest.clients.length ? ', ' : '' }}</a
                  >]</atoms-text
                >
                <atoms-text v-else><v-icon left small>mdi-domain</v-icon> - </atoms-text>
                <atoms-text>
                  <v-icon left small>mdi-map-marker</v-icon>
                  {{ rest.address.city }}
                </atoms-text>
              </v-col>
            </v-row>
            <!--            <v-container class="px-0 pb-0">-->
            <!--              <atoms-text-->
            <!--                ><v-icon left small>mdi-cash-multiple</v-icon>-->
            <!--                {{ $idCurrency(rest.balance) || "0" }}</atoms-text-->
            <!--              >-->
            <!--              <atoms-text v-if="rest.clientIds && rest.clientIds.length > 0">-->
            <!--                <v-icon left small>mdi-domain</v-icon>-->
            <!--                [<a-->
            <!--                  v-for="(x, i) in rest.clients"-->
            <!--                  :key="i"-->
            <!--                  class="text-capitalize"-->
            <!--                  :href="`/attendance/riwayat?search=${rest._id}&client=${x._id}`"-->
            <!--                >-->
            <!--                  {{ x.name }}-->
            <!--                  {{ i + 1 !== rest.clients.length ? ", " : "" }}</a-->
            <!--                >]</atoms-text-->
            <!--              >-->
            <!--              <atoms-text v-else-->
            <!--                ><v-icon left small>mdi-domain</v-icon> - -->
            <!--              </atoms-text>-->
            <!--            </v-container>-->
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.worker.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada pekerja yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <!-- edit balance -->
    <v-slide-x-reverse-transition>
      <organism-workers-edit-balance
        v-if="showEdit"
        :data="showEdit"
        :pureEdit="true"
        @closed="
          () => {
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>

    <v-slide-x-reverse-transition>
      <organism-workers-edit-balance-bulk
        v-if="showEditBulk"
        @closed="
          () => {
            showEditBulk = null
          }
        "
      />
    </v-slide-x-reverse-transition>

    <!-- history -->
    <v-slide-x-reverse-transition>
      <organism-workers-history-balance
        v-if="showHistory"
        :data="showHistory"
        :pureEdit="true"
        @closed="
          () => {
            showHistory = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      sort: '',
      showEditBulk: false,
      removeValidator: '',
      showRemove: null,
      showDetail: null,
      showAssign: null,
      showEdit: null,
      showHistory: null,
      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      filter: null,
      filters: [
        {
          title: 'Rekening BCA',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.BCA,
              $options: 'i',
            },
          },
        },
        {
          title: 'Rekening OVO',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.OVO,
              $options: 'i',
            },
          },
        },
        {
          title: 'Rekening GOPAY',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.GoPay,
              $options: 'i',
            },
          },
        },
        {
          title: 'Kapten',
          value: {
            'worker.captain': true,
          },
        },
      ],
      constant: ['Desc', 'Asc'],
      reportLoading: false,
      mainLoading: false,
      enableDate: false,
      exportData: {
        email: 'arsip.hayokerja@gmail.com',
      },
      showCheckboxForSelectingDataWorker: false,
    }
  },
  watch: {
    'client.value'() {
      if (this.client.value) {
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
        this.loadData()
      }
    },
    page: {
      handler() {
        this.getWorkers()
      },
      deep: true,
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  computed: {
    filterQuery: function () {
      const term = { $regex: this.term || '', $options: 'i' }
      return {
        pipeline: [
          {
            $sort: {
              _updatedDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          {
            $lookup: {
              from: 'Workers',
              localField: '_id',
              foreignField: '_id',
              as: 'worker',
            },
          },
          {
            $unwind: {
              path: '$worker',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $addFields: {
              clientIds: {
                $reduce: {
                  input: '$worker.clientIds',
                  initialValue: [],
                  in: {
                    $cond: {
                      if: {
                        $eq: [
                          {
                            $type: '$$this',
                          },
                          'string',
                        ],
                      },
                      then: { $concatArrays: ['$$value', ['$$this']] },
                      else: { $concatArrays: ['$$value', '$$this'] },
                    },
                  },
                },
              },
            },
          },
          ...this.$store.state.worker.defaultPipeline,
          {
            $match: {
              _updatedDate: this.enableDate
                ? {
                    $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                    $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
                  }
                : { $exists: true },
              $or: [
                {
                  'worker.idNumber': term,
                },
                {
                  'worker.fullName': term,
                },
                {
                  'worker.email': term,
                },
                {
                  'worker.phone': term,
                },
                {
                  'worker.birthplace': term,
                },
                {
                  'worker.address.city': term,
                },
              ],
              clientIds: this.client.item?._id ? { $in: [this.client.item._id], $exists: true } : undefined,
              ...(this.filter && this.filter.length > 0
                ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                : []),
            },
          },
        ],
      }
    },
  },
  methods: {
    loadData() {
      this.page = 1
      this.getWorkers()
    },
    async getWorkers() {
      this.mainLoading = true
      const dateNow = new Date()
      if (new Date(this.to.value) > dateNow) {
        this.$showDialog({
          title: 'Terjadi kesalahan',
          body: 'tanggal akhir tidak bisa melebihi hari tanggal hari ini!',
        })
        return
      }

      if (new Date(this.from.value) > new Date(this.to.value)) {
        this.$showDialog({
          title: 'Terjadi kesalahan',
          body: 'tanggal awal tidak bisa melebihi tanggal akhir!',
        })
        return
      }

      try {
        await this.$store.dispatch('worker/getWorkerBalances', {
          custom: this.filterQuery,
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        console.log(this.$store.state.worker.data)
        this.mainLoading = false
      }
    },

    //@test dinta
    // async createRequest(payload) {
    //   this.mainLoading = true
    //   try {
    //     console.log(payload)
    //     await this.$api
    //       .post("RequestPayments/save", {
    //         nominal: 5000,
    //       })
    //       .then(({ ...rest }) => {
    //         console.log(rest)
    //       })
    //   } catch (error) {
    //     Promise.reject(error)
    //   } finally {
    //     this.mainLoading = false
    //   }
    // },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
          limit: 0,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        console.log(this.$store.state.worker.data)
        this.$store.commit('SET', { loading: false })
      }
    },
    async exportXlsx() {
      const isConfirmed = confirm('Sudah yakin untuk ekspor datanya?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      try {
        const filter = this.filterQuery
        const realm = await this.$realm()
        const items = await realm.functions.getWorkerBalances(JSON.stringify(filter))
        const columns = [
          {
            label: '_id',
            value: '_id',
          },
          {
            label: 'Transfer Type',
            value: 'Transfer Type',
          },
          {
            label: 'Credited Account',
            value: 'Credited Account',
          },
          {
            label: 'Amount',
            value: 'Amount',
          },
          {
            label: 'Remark 1',
            value: 'Remark 1',
          },
          {
            label: 'Remark 2',
            value: 'Remark 2',
          },
          {
            label: 'Rcv Name',
            value: 'Rcv Name',
          },
          {
            label: 'Email',
            value: 'Email',
          },
          {
            label: 'ㅤ',
            value: 'ㅤ',
          },
        ]
        const content = items.map((item) => {
          const accountNumber = item.worker?.accountDetails?.accountNumber || ''
          return {
            _id: item._id,
            'Transfer Type': item.worker?.accountDetails?.bankName,
            'Credited Account': accountNumber,
            Amount: item.balance,
            'Remark 1': ' ',
            'Remark 2': item.worker?.accountDetails?.accountName,
            'Rcv Name': item.worker?.fullName,
            Email: this?.exportData?.email || 'arsip.hayokerja@gmail.com',
            ㅤ: accountNumber.length,
          }
        })
        let data = [
          {
            sheet: 'Result',
            columns,
            content,
          },
        ]
        let settings = {
          fileName: `Saldo Pekerja ${this.$moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$xlsx(data, settings)
        await this.getWorkers()
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          return this.$showDialog({
            title: 'Maaf',
            body: 'Penarikan data gagal',
          })
        }
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
    async resetAllBalances() {
      const isConfirmed = confirm(
        'Sudah yakin untuk reset semua saldo pekerja? Pastikan anda sudah export xlsx sebelum mengeksekusi.',
      )
      if (!isConfirmed) return
      const validateBalance = prompt(
        `Masukkan total saldo ( ${this.$store.state.worker.total} ), untuk melanjutkan reset saldo`,
      )
      if (!validateBalance) return
      if (+validateBalance !== +this.$store.state.worker.total) return alert('Inputan total saldo salah')
      const { value: password } = await this.$swal.fire({
        title: 'Masukkan Password Anda',
        input: 'password',
        inputPlaceholder: 'Masukkan Password Anda',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        showLoaderOnConfirm: true,
      })
      if (!password) return
      this.$store.commit('SET', { loading: true })
      try {
        await this.$api.post(`WorkerBalances/reset`, {
          validateBalance,
          password,
        })
        await this.getWorkers()
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>

<style scoped>
.swal-blue {
  background-color: dodgerblue;
}
</style>
